import React from 'react';

import { Footer as FooterContent, IconSpinner, LayoutComposer } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';

import { validProps } from './Field';

import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';

import css from './PageBuilder.module.css';

const getMetadata = (meta, schemaType, fieldOptions, pageId) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  const faqPageSchemaForSEO = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': [
      {
        '@type': 'Question',
        'name': "What's the average price to rent a boat in Miami?",
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': 'With Upisle.com, you have the ability to search and view a wide selection of Miami-based boats that vary in rental prices from $150 per hour to $3,400 per day. You can simply filter your boat rental searches on Upisle by price, passenger capacity, amenities and more.'
        }
      }, {
        '@type': 'Question',
        'name': 'Do I need a license to rent a boat in Miami?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': 'In order to operate a motorboat of ten (10) horsepower or greater, Florida law requires anyone who was born on or after Jan. 1, 1988 to successfully complete an approved boating safety course and obtain a Boating Safety Education Identification Card issued by the FWC. Upisle.com also offers plenty of boat charters that provide boat captains as well.'
        }
      }, {
        '@type': 'Question',
        'name': 'How many people are allowed on a boat?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': 'Regulations in place limit most vessels to carry no more than 12 passengers regardless of the size of the boat and its ability to have space for more passengers. The reason for this is because a vessel is considered to be a passenger ship and has to comply with the SOLAS convention (Safety of Life at Sea) set out by the IMO (International Maritime Organization) in order to carry more than 12 passengers.'
        }
      }, {
        '@type': 'Question',
        'name': 'What are the most popular boat rental destinations in Miami?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': "If you're renting a boat in Miami, there's a long list of popular destinations you must experience. Haulover Sandbar, Star Island, Key Biscayne, Marine Stadium, Miami River, Bayside, Brickell, Stiltsville, Miami Beach, Fisher Island, Indian Creek and Flagler Monument Island are all incredible Miami boating destinations."
        }
      }, {
        '@type': 'Question',
        'name': 'How many hours should I rent a boat for?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': 'The average amount of time boats are rented in Miami are for 4 hours, 6 hours or 8 hours. Luckily, Upisle offers a wide variety of boat rentals that can be rented for anywhere between 1 hour to multiple days.'
        }
      }, {
        '@type': 'Question',
        'name': 'What is a boat marketplace?',
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': 'A boat marketplace is a platform where individuals can browse for boat rentals within their price range, location, passenger count and more. Upisle.com for example, is the worlds fastest growing peer to peer boat rental marketplace where renters can search, rent and enjoy boats, jet skis, yachts and fishing charters at an affordable price.'
        }
      }
    ]
  }

  const isFAQPage = pageId === "miami_boat_rentals";

  return {
    title,
    description,
    schema: isFAQPage ? faqPageSchemaForSEO : pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner />
    </div>
  );
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */
const PageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    history,
    intl,
    location,
    routeConfiguration,
    currentUser,
    withSectionSeparator,
    currentScrollFromTop,
    pageId,
    ...pageProps
  } = props;

  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }

  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents, pageId);

  const layoutAreas = `
    topbar
    main
    footer
  `;
  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer />
              </Topbar>
              <Main as="main" className={css.main}>
                {inProgress ? (
                  <LoadingSpinner />
                ) : (
                  <SectionBuilder
                    sections={sections}
                    options={options}
                    history={history}
                    intl={intl}
                    location={location}
                    routeConfiguration={routeConfiguration}
                    currentUser={currentUser}
                    withSectionSeparator={withSectionSeparator}
                    currentScrollFromTop={currentScrollFromTop}
                  />
                )}
              </Main>
              {currentScrollFromTop !== 0 && (
                <Footer>
                  <FooterContent />
                </Footer>
              )}
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, StaticPage, SectionBuilder };

export default PageBuilder;
